<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-sbpm-properties' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Properties</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>{{ property.property_name }}, {{ property.postcode }}</h1>
        </v-col>

        <v-col cols="auto" class="pr-12">
          <v-btn
            v-if="property.status != 'Managing'"
            small
            depressed
            class="mr-2"
            color="green green--text lighten-4"
            :loading="pdfLoading"
            @click="downloadPdf"
            >Viewing List</v-btn
          >
        </v-col>
      </v-row>

      <v-row align="center" class="pr-12" v-if="property.status != 'Managing'">
        <v-col cols="auto">
          <v-icon left>mdi-map-marker</v-icon>
          {{ property.property_name }}, {{ property.postcode }}
        </v-col>
        <v-col cols="auto">
          <v-icon left class="ml-2">mdi-cash</v-icon>£{{
            formatPrice(property.rent)
          }}p/m
        </v-col>
        <v-col cols="auto">
          <span
            >Available From:
            {{ formatDate(property.available_from, false) }}</span
          >
        </v-col>
        <v-col cols="auto">
          <span>Pets: No</span>
        </v-col>
        <v-col cols="auto">
          <span>Smokers: No</span>
        </v-col>

        <v-col cols="auto">
          <span>SME ID: {{ property.sme_id }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Property Details</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.propertySmallDialog.openForm(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr v-if="property.status != 'Managing'">
                  <td>360 Viewing Link</td>
                  <td>{{ property.url_360 }}</td>
                </tr>
                <tr v-if="property.status != 'Managing'">
                  <td>Closing Date</td>
                  <td>
                    {{
                      property.closing_date
                        ? property.formatted_dates.close
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr v-if="property.status != 'Managing'">
                  <td>Viewing Date</td>
                  <td>
                    {{
                      property.viewing_date
                        ? property.formatted_dates.viewing
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr v-if="property.status != 'Managing'">
                  <td>Viewing Time</td>
                  <td>
                    {{ property.viewing_time ? property.viewing_time : "N/A" }}
                  </td>
                </tr>

                <tr v-if="property.status != 'Managing'">
                  <td>Move In Date</td>
                  <td>
                    {{
                      property.move_in_date
                        ? formatDate(property.move_in_date)
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr v-if="property.status != 'Managing'">
                  <td>Open to applications</td>
                  <td>{{ property.open_to_applications ? "Yes" : "No" }}</td>
                </tr>

                <tr v-if="property.status == 'Managing'">
                  <td>Address</td>
                  <td>{{ property.property_name }}</td>
                </tr>

                <tr v-if="property.status == 'Managing'">
                  <td>Status</td>
                  <td>{{ property.status }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checkouts</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.checkOutDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="checkoutTableHeaders"
              :items="property.checkouts"
              no-data-text="No Checkouts"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-checkout-individual',
                        params: { checkoutId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Communal Repairs</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.repairDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="repairTableHeaders"
              :items="property.repairs"
              no-data-text="No Repairs"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-communalrepairs-individual',
                        params: { repairId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Landlord Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="property.status != 'Managing'"
                    small
                    depressed
                    color="green lighten-4 gren--text"
                    class="mr-2"
                    :disabled="
                      !property.landlord_email && !property.landlord_email_2
                    "
                    v-on="on"
                    @click="sendToLandlordPrompt()"
                  >
                    <v-icon small>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Send 360 Viewing</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="property.status != 'Managing'"
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.landlorsDialog.openForm(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{{ property.landlord_name }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ property.landlord_email }}</td>
                </tr>
                <tr>
                  <td>Email 2</td>
                  <td>{{ property.landlord_email_2 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Move Outs</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.moveOutDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="checkoutTableHeaders"
              :items="property.moveouts"
              no-data-text="No moveouts"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-moveout-individual',
                        params: { moveoutId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mb-6" v-if="property.status != 'Managing'">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Application Count </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 gren--text"
                    v-on="on"
                    :disabled="
                      !property.landlord_email && !property.landlord_email_2
                    "
                    @click="sendToApplicationGroup()"
                  >
                    <v-icon small>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Send</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <td>Total Submitted</td>
                  <td>{{ applications_group_submited.length }}</td>
                </tr>
                <tr>
                  <td>Invited to Viewing</td>
                  <td>{{ applications_group_invited.length }}</td>
                </tr>
                <tr>
                  <td>Confirmed Viewings</td>
                  <td>
                    {{ applications_group_attending_viewing.length }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(property)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="property.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.note_text="{ item }">
                <span v-html="item.note_text"></span>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="property.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="property.status != 'Managing'">
        <div outlined class="mb-6">
          <v-btn
            color="red lighten-4 red--text"
            depressed
            @click="$refs.rejectDialog.rejectAllIfStatuses()"
            >Reject All</v-btn
          >
        </div>

        <v-toolbar flat dark dense :color="appColor">
          <v-toolbar-title>
            <div v-if="applications_group.length > 0">Applications</div>
            <div v-else>
              There are currently no applications for this property
            </div>
          </v-toolbar-title>
        </v-toolbar>

        <v-simple-table
          class="mb-5 table table-bordered"
          v-for="group in applications_group"
          v-bind:key="group.id"
        >
          <tbody>
            <tr class="grey lighten-3">
              <td colspan="7">
                <div v-if="group.applications.length == 1">Individual</div>
                <div v-else>
                  Group: {{ group.group_name }}

                  <span v-if="group.applications.length > 1" class="ml-2">
                    <span
                      v-if="(property.rent * 30) / 12 <= groupSumSolary(group)"
                    >
                      <v-icon color="green">mdi-check-circle </v-icon>
                    </span>
                    <span v-else>
                      <v-icon color="red">mdi-close-circle </v-icon>
                    </span>
                  </span>
                </div>
              </td>
              <td>
                <v-btn
                  v-if="currentUserRole.name == 'VH Superadmin'"
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  class="mr-2"
                  @click="$refs.movePropertyDialog.open(group)"
                >
                  Move Property
                </v-btn>
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <th>Employment</th>
              <th>Affordability</th>
              <th>Landlord</th>
              <th>Pets</th>
              <th>Smoker</th>
              <th>Status</th>

              <th>
                <v-btn
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  class="mr-2"
                  @click="$refs.rejectDialog.rejectGroup(group)"
                >
                  Reject
                </v-btn>
              </th>
            </tr>

            <tr
              v-for="application in group.applications"
              v-bind:key="application.id"
            >
              <td
                v-bind:class="
                  application.interested_in_property == 1
                    ? 'light-blue lighten-5'
                    : ' '
                "
              >
                {{ application.full_name }}
              </td>
              <td>{{ application.employment_status }}</td>
              <td>
                <div v-if="application.monthly_salary">
                  <div
                    v-if="
                      (property.rent * 30) / 12 <= application.monthly_salary
                    "
                  >
                    <v-icon color="green">mdi-check-circle </v-icon>
                  </div>
                  <div v-else>
                    <v-icon color="red">mdi-close-circle </v-icon>
                  </div>
                </div>
                <div v-else>
                  <v-icon color="yellow">mdi-help-circle</v-icon>
                </div>
              </td>

              <td>
                <div v-if="application.current_landlord">
                  <v-icon color="green">mdi-check-circle </v-icon>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-close-circle </v-icon>
                </div>
              </td>
              <td>
                <div v-if="application.pets">
                  <v-icon color="green">mdi-check-circle </v-icon>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-close-circle </v-icon>
                </div>
              </td>
              <td>
                <div v-if="application.smoker">
                  <v-icon color="green">mdi-check-circle </v-icon>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-close-circle </v-icon>
                </div>
              </td>
              <td>
                <v-btn
                  x-small
                  depressed
                  :color="
                    application.status.colour +
                    ' ' +
                    (application.status.colour == 'yellow'
                      ? 'black--text lighten-5'
                      : application.status.colour + '--text lighten-5')
                  "
                >
                  {{ application.status.status_desc }}
                </v-btn>
              </td>

              <td>
                <v-btn
                  x-small
                  depressed
                  color="green lighten-4 green--text"
                  class="mr-2"
                  :to="{
                    name: 'module-sbpm-applications-individual',
                    params: { applicationId: application.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  bottom
                  x-small
                  depressed
                  class="mr-2"
                  color="red lighten-4 red--text"
                  @click="openDelete(application, true)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>

                <v-btn
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  class="mr-2"
                  @click="$refs.rejectDialog.changeStatusPrompt(application)"
                >
                  Reject
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-container>
    <RejectDialog ref="rejectDialog" :fromProperty="true" />
    <PropertySmallDialog ref="propertySmallDialog" />
    <LandlorsDialog ref="landlorsDialog" />
    <MovePropertyDialog ref="movePropertyDialog" />
    <NoteDialog ref="noteDialog" type="property" />

    <move-out-dialog
      ref="moveOutDialog"
      :property="property.id"
      :fromProperty="true"
    />
    <check-out-dialog
      ref="checkOutDialog"
      :property="property.id"
      :fromProperty="true"
    />

    <repair-dialog
      ref="repairDialog"
      :property="property"
      :fromProperty="true"
    />

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Form</v-card-title>
        <v-card-text>Are you sure you wish to archive form?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendToLandlord.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Send to Landlord</v-card-title>
        <v-card-text
          >Are you sure you wish to send this to
          <strong>
            {{ property.landlord_email }}
            {{ property.landlord_email_2 }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetSendToLandlord"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="sendToLandlord.loading"
            @click="sendToLandlordConfirmed"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendToApplications.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Send to Landlord</v-card-title>
        <v-card-text
          >Are you sure you want to send the email to the landlord email
          addresses
          <strong>
            {{ property.landlord_email }}
            {{ property.landlord_email_2 }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetSendToApplications"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="sendToApplications.loading"
            @click="sendToApplicationsConfirmed"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RejectDialog from "./components/RejectDialog.vue";
import PropertySmallDialog from "./components/PropertySmallDialog.vue";
import LandlorsDialog from "./components/LandlorsDialog.vue";
import MovePropertyDialog from "./components/MovePropertyDialog.vue";
import NoteDialog from "../notes/NoteDialog.vue";

import MoveOutDialog from "../managed-properties/components/MoveOutDialog.vue";
import CheckOutDialog from "../managed-properties/components/CheckOutDialog.vue";
import RepairDialog from "../communal-repairs/components/RepairDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    MoveOutDialog,
    CheckOutDialog,
    RejectDialog,
    PropertySmallDialog,
    LandlorsDialog,
    MovePropertyDialog,
    NoteDialog,
    RepairDialog,
  },

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      sendToLandlord: {
        errors: {},
        dialog: false,
        loading: false,
      },
      sendToApplications: {
        errors: {},
        dialog: false,
        loading: false,
      },
      activeHover: false,
      myIcon: {
        name: "mdi-unfold-less-vertical",
        color: "default",
      },
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-sbpm-properties" },
          exact: true,
        },
      ],
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      checkoutTableHeaders: [
        { text: "Date/Time", value: "due_date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      repairTableHeaders: [
        { text: "Works", value: "short_description" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
    };
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    property() {
      return this.$store.getters["sbpm/propertiesStore/get"];
    },

    applications_group() {
      let groups = this.$store.getters["sbpm/propertiesStore/groups"];

      return groups;
    },

    applications_group_attending_viewing() {
      let groups = this.$store.getters["sbpm/propertiesStore/groups"];

      let test = groups;

      let applications = [];
      groups.forEach((s) => {
        s.applications.forEach((application) => {
          if (application.attending_viewing == "1") {
            //and if group not added yet

            const index = applications.findIndex(
              (object) => object.id === s.id
            );

            if (index === -1) {
              applications.push(s);
            }
          }
        });
      });
      test = applications;

      return test;
    },

    applications_group_invited() {
      let groups = this.$store.getters["sbpm/propertiesStore/groups"];

      let test = groups;

      let applications = [];
      groups.forEach((s) => {
        s.applications.forEach((application) => {
          if (application.invited_to_viewing == "1") {
            const index = applications.findIndex(
              (object) => object.id === s.id
            );

            if (index === -1) {
              applications.push(s);
            }
          }
        });
      });
      test = applications;

      return test;
    },

    applications_group_submited() {
      let groups = this.$store.getters["sbpm/propertiesStore/groups"];

      let test = groups;

      let applications = [];
      groups.forEach((s) => {
        s.applications.forEach((application) => {
          if (application.status.status_desc != "Managing") {
            //and if group not added yet

            const index = applications.findIndex(
              (object) => object.id === s.id
            );

            if (index === -1) {
              applications.push(s);
            }
          }
        });
      });
      test = applications;

      return test;
    },
  },
  methods: {
    sendToApplicationGroup() {
      this.sendToApplications.dialog = true;
    },

    resetSendToApplications() {
      this.sendToApplications.errors = null;
      this.sendToApplications.dialog = false;
      this.sendToApplications.loading = false;
    },

    sendToApplicationsConfirmed() {
      const appId = this.$route.params.id;
      const propertyId = this.$route.params.propertyId;

      this.sendToApplications.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/sendToSubmittedApplications", {
          appId,
          propertyId,
        })
        .then(() => {
          this.resetSendToApplications();
        })
        .catch((err) => {
          this.sendToApplications.errors = err.response.data.errors;
          this.sendToApplications.loading = false;
        });
    },

    sendToLandlordPrompt() {
      this.sendToLandlord.dialog = true;
    },
    resetSendToLandlord() {
      this.sendToLandlord.errors = null;

      this.sendToLandlord.dialog = false;

      this.sendToLandlord.loading = false;
    },
    sendToLandlordConfirmed() {
      const appId = this.$route.params.id;
      const propertyId = this.$route.params.propertyId;

      this.sendToLandlord.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/sendToLandlord", {
          appId,
          propertyId,
        })
        .then(() => {
          this.resetSendToLandlord();
        })
        .catch((err) => {
          this.sendToLandlord.errors = err.response.data.errors;
          this.sendToLandlord.loading = false;
        });
    },

    groupSumSolary(group) {
      let sum = 0;

      group.applications.forEach((application) => {
        if (application.monthly_salary == null) {
          application.monthly_salary = 0;
        }
        sum += parseInt(application.monthly_salary);
      });

      return sum;
    },

    downloadPdf() {
      const appId = this.$route.params.id;

      const propertyId = this.$route.params.propertyId;
      this.pdfLoading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/downloadViewingListPdf", {
          appId,
          propertyId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },

    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteForm", {
          appId: this.$route.params.id,
          applicationId: this.deleteForm.form.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style>
.table-bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.table-bordered tr td,
.table-bordered tr th {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>
